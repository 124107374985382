interface devicePosition {
  x: number,
  y: number
}

interface networkPosition {
  x: number,
  y: number,
  reach: number
}

interface bestNetwork {
  x: number,
  y: number,
  speed: number
}

export const getDistance = (
  device: devicePosition,
  network: networkPosition
): number => Math.sqrt(
  Math.pow((device.x - network.x), 2) + Math.pow((device.y - network.y), 2)
)

export const getSpeed = (reach: number, distance: number): number => {
  if (distance > reach) return 0
  else return Math.round((reach - distance) * 2 * 100) / 100
}

export const getBestNetwork = (networks: Array<networkPosition>, device: devicePosition): bestNetwork | undefined => {
  const speedsArray = networks.map((network) => (
    {
      x: network.x,
      y: network.y,
      speed: getSpeed(network.reach, getDistance(device, network))
    }
  ))
  const maxSpeed = Math.max(...speedsArray.map(item => item.speed), 0)
  return speedsArray.find(item => item.speed === maxSpeed)
}
