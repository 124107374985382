import React from 'react'
import { createUseStyles } from 'react-jss'
import { getBestNetwork } from './utils/utils'
import { networkStations } from './data/networkStations'
import { devicePositions } from './data/devicePositions'
import ResultsDisplayCard from './components/ResultsDisplayCard/ResultsDisplayCard'

const styles = createUseStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
  },
  result: {
    color: '#161632',
    fontSize: 14,
    marginBottom: 8
  }
})

const App: React.FC = () => {
  const classes = styles()

  return (
    <div className={classes.root}>
      <ResultsDisplayCard>
        {devicePositions.map((item, index) => (
          <div className={classes.result} key={index}>
            {getBestNetwork(networkStations, item)?.speed !== 0 ?
              `Best network station for point (${item.x},${item.y}) is
              (${getBestNetwork(networkStations, item)?.x},${getBestNetwork(networkStations, item)?.y}) 
              with speed ${getBestNetwork(networkStations, item)?.speed}`
              :
              `No network station within reach for point (${item.x},${item.y})`
            }
          </div>
        ))}
      </ResultsDisplayCard>
    </div>
  )
}

export default App
