import React, { ReactNode } from 'react'
import { createUseStyles } from 'react-jss'

interface Props {
  children: ReactNode // children elements
}

const styles = createUseStyles({
  wrapper: {
    width: '50%',
    borderRadius: '8px',
    padding: 40,
    borderColor: 'gray',
    backgroundColor: '#eeeeee',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  title: {
    fontSize: 56,
    color: '#ff2837',
    fontWeight: 700,
    marginBottom: 8
  },
  smallText: {
    fontSize: 10,
    color: '#3f3d3d',
    marginBottom: 48
  }
})

const ResultsDisplayCard: React.FC<Props> = ({ children }): React.ReactElement => {
  const classes = styles()
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        Nordcloud assignment
      </div>
      <div className={classes.smallText} data-testid='network'>
        Made by Hang Le
      </div>
      {children}
    </div>
  )
}

export default ResultsDisplayCard