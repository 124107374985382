export const devicePositions = [
  {
    x: 0,
    y: 0
  },
  {
    x: 100,
    y: 100
  },
  {
    x: 15,
    y: 10
  },
  {
    x: 18,
    y: 18
  },
  {
    x: 13,
    y: 13
  },
  {
    x: 25,
    y: 99
  }
]